<template>
  <div class="index">
    <van-nav-bar
      title="回复时控"
      left-text="返回"
      left-arrow
      right-text="筛选"
      fixed
      placeholder
      @click-left="onClickLeft"
      @click-right="onClickRight"
    />
    <!-- <div class="title-serch">
      <van-search
          style="flex: 1;"
          v-model="serchValue"
          show-action
          placeholder="输入群发账号"
          shape="round"
          @search="onSearch"
          @clear="clearSerch"
      >
        <template #action >
          <div @click="routerPush('/contactMessageBatchSend/create')" style="    display: flex;
    align-items: center; color:#2b60dd;    font-size: 26px;">
            <van-icon style="font-size: 26px" name="search" />
          </div>
        </template>
      </van-search>
    </div> -->
    <div class="content">
      <van-list
        v-model="loading"
        :finished="finished"
        @load="onLoad"
      >
        <div class="list-item" v-for="(item, index) in list" :key="index" @click="check(item)">
          <div class="employee">
            <div>员工：{{ item.employee }}</div>
            <div>客户最后消息</div>
          </div>
          <div class="msg">
            <div></div>
            <div class="msg-box">
              <div class="msg-box-right">
                <div class="msg-box-right-bubble">
                  {{ item.msg_text }}
                </div>
                <div class="msg-box-right-time">{{ item.msg_time }}</div>
              </div>
              {{ item.contact }}
            </div>
          </div>
          <div class="handle">
            更多
          </div>
        </div>
        <div v-if="finished && list.length" slot="finished">无更多数据</div>
      </van-list>
      <van-empty
        v-if="finished && !list.length"
        class="custom-image"
        :image="require('../../assets/images/search-no-data.svg')"
        description="暂无数据"
      />
    </div>
    <!--  -->
    <van-dialog v-model="showChatRecord" title="聊天记录" confirm-button-color="#1989fa">
      <div class="chatRecord">
        <div v-if="chatRecord && chatRecord.msgList" class="labelContent phoneLog">
          <div v-for="(item, index) in chatRecord.msgList" :key="index">
            <div class="sendBox" :class="{ 'aiSend': item.msg_type == 0, 'kehu': item.msg_type == 1 }">
              <span class="neme" v-show="item.msg_type == 0">{{ item.sender }}</span>
              <div>
                <div class="content">
                  <div v-if="item.msg_content.type == 'text'">{{ item.msg_content.content }}</div>
                  <div v-if="item.msg_content.type == 'link'">
                    <a class="link" :href="item.msg_content.link_url">
                      <img :src="item.msg_content.image_url" />
                      <div>
                        {{ item.msg_content.title }}
                        <p>
                          {{ item.msg_content.description }}
                        </p>
                      </div>
                    </a>
                  </div>
                  <div v-if="item.msg_content.type == 'image'" class="image">
                    <img :src="item.msg_content.imageFullPath" />
                  </div>
                  <div v-if="item.msg_content.type == 'video'" class="video">
                    <img src="../../assets/link-url.png">
                    {{ item.msg_content.videoName }}
                  </div>
                </div>
                <div class="time">{{ item.send_time }}</div>
              </div>
              <span class="neme" v-show="item.msg_type == 1">{{ item.sender }}</span>
            </div>
          </div>
        </div>
        <!-- <div v-if="chatRecord.msgList && chatRecord.msgList.length">
          <div v-for="(item, index) in chatRecord.msgList" :key="index" class="sendBox">
            <div class="contcat">发送人：{{ item.sender }} <span>{{ item.send_time }}</span></div>
            <div class="msg">
              <div v-if="item.msg_content.type == 'text'">{{ item.msg_content.content }}</div>
              <div v-if="item.msg_content.type == 'link'">
                <a class="link" :href="item.msg_content.link_url">
                  <img :src="item.msg_content.image_url" />
                  <div>
                    {{ item.msg_content.title }}
                    <p>
                      {{ item.msg_content.description }}
                    </p>
                  </div>
                </a>
              </div>
              <div v-if="item.msg_content.type == 'image'" class="image">
                <img :src="item.msg_content.imageFullPath" />
              </div>
              <div v-if="item.msg_content.type == 'video'" class="video">
                <img src="../../assets/link-url.png">
                {{ item.msg_content.videoName }}
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </van-dialog>
    <van-popup v-model="showRight" position="right" :style="{ height: '100%', width: '80%' }">
      <div class="screen">
        <div class="list-item">
          <div class="item-title">
            成员
          </div>
          <div class="item-content" @click="$refs.employee.$show(StandbyStaffList,'max')">
            {{ StandbyStaffList.length ? StandbyStaffList[0].name : '点击选择' }}
          </div>
        </div>
        
        <div class="list-item">
          <div class="item-title">
            记录时间
          </div>
          <div class="item-content">
            <div @click="showTime = true" style="flex: 1;text-align: center">{{ startDayStr ? startDayStr : '开始时间' }}</div>
            -
            <div @click="showTime = true" style="flex: 1;text-align: center">{{ endDayStr ? endDayStr : '结束时间' }}</div>
            <van-icon name="close" v-show="startDayStr || endDayStr" @click="clearTime"/>
          </div>
        </div>
        <div class="">
        <van-button style="width: 100%" type="info" @click="onSearch">筛选</van-button>
      </div>
      </div>
    </van-popup>
    <van-action-sheet v-model="showTime" :close-on-click-overlay="false">
      <van-datetime-picker
        v-model="currentDate.startDay"
        type="date"
        title="开始日期"
        :formatter="formatter"
        :visible-item-count="4"
      >
        <div slot="confirm"></div>
        <div slot="cancel"></div>
      </van-datetime-picker>
      <van-datetime-picker
        v-model="currentDate.endDay"
        type="date"
        title="结束日期"
        :formatter="formatter"
        :visible-item-count="4"
      >
        <div slot="confirm"></div>
        <div slot="cancel"></div>
      </van-datetime-picker>
      <div class="showSelectDayBtn">
        <van-button @click="showTime = false">取消</van-button>
        <van-button type="info" @click="setDate">确认</van-button>
      </div>
    </van-action-sheet>
    <employeeIndex ref="employee" @change="userChange"/>
    <departmentSelect ref="department"/>
  </div>
</template>
<script>
import { getCookie } from '@/plugins/utils'
import { ReplyMonitorApi } from '@/api/replyMonitor'
import employeeIndex from "../channelCode/components/employeeIndex"
import departmentSelect from "@/components/departmentSelect"

export default {
  components: {
    employeeIndex,
    departmentSelect
  },
  data() {
    return {
      serchValue: '',
      loading: false,
      finished: false,
      list: [],
      page: 1,
      perPage: 15,

      showChatRecord: false,
      chatRecord: {},

      showRight: false,
      showTime: false,
      currentDate: {
        startDay: new Date(),
        endDay: new Date()
      },
      startDayStr: '',
      endDayStr: '',
      showPicker: false,

      // 员工
      StandbyStaffList: [],
    }
  },
  created () {
    window.scrollTo(0, 0)
  },
  methods: {
   
    // 返回上一页
    onClickLeft() {
      this.$router.back()
    },
    // 返回上一页
    onClickRight() {
      this.showRight = true
    },
    onLoad () {
      this.loading = true
      let param = {
        page: this.page,
        page_size: this.perPage
      }
      if (this.StandbyStaffList.length && this.StandbyStaffList[0]) param.employee_id = this.StandbyStaffList[0].id
      if (this.startDayStr) param.created_at_mt = this.startDayStr + ' 00:00'
      if (this.endDayStr) param.created_at_lt = this.endDayStr + ' 23:59'
      ReplyMonitorApi.index(param).then(res => {
        this.loading = false
        if(res.data.lists.data.length < this.perPage){
          this.finished = true
          this.loading = false
        }else{
          this.page++
          this.finished = false
          this.loading = false
        }
        this.showRight = false
        this.list = this.list.concat(res.data.lists.data || [])
      })
    },
    check (item) {
      ReplyMonitorApi.show({
        id: item.id
      }).then(res => {
        this.chatRecord = res.data
        this.showChatRecord = true
      })
    },
    userChange(data, info) {
      console.log(data)
      this.StandbyStaffList = data
      // this.StandbyStaff = data.map(i=>i.name)
    },
    dateFormatStr (time) {
      var Y = time.getFullYear() + '-';
      var M = (time.getMonth() + 1 < 10 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1) + '-';
      var D = (time.getDate() < 10 ? '0' + time.getDate() : time.getDate());
      return Y + M + D
    },
    setDate () {
     this.startDayStr = this.dateFormatStr(this.currentDate.startDay)
     this.endDayStr = this.dateFormatStr(this.currentDate.endDay)
     this.showTime = false
    },
    clearTime () {
      this.startDayStr = ''
      this.endDayStr = ''
    },
    onSearch () {
      this.list = []
      this.page = 1
      this.onLoad()
    },
    clearSerch () {},
    // 路由跳转
    routerPush(e) {
      this.$router.push(e)
    },
    formatter(type, val) {
      if (type === 'year') {
        return val + '年';
      }
      if (type === 'month') {
        return val + '月';
      }
      if (type === 'day') {
        return val + '日';
      }
      return val;
    },
  }
}
</script>
<style scoped lang="less">
.index {
  font-size: 14px;
  height: 100%;
  background: #f4f5f7;

  /deep/ .van-nav-bar__content {
    background: #2b63cd;
    color: #fff;
  }

  /deep/ .van-nav-bar .van-icon {
    color: #fff;
  }

  /deep/ .van-nav-bar__text {
    color: #fff;
  }

  /deep/ .van-nav-bar__title {
    color: #fff;
  }

  /deep/ .van-dialog__header {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  >.content {
      // background: #f4f5f7;
      padding: 16px 12px;

    .list-item {
      padding: 12px;
      margin-bottom: 16PX;
      background: #fff;
      border-radius: 6px;
      box-shadow: 2px 0px 6px rgba(0, 0, 0, .05);

      .employee {
        padding-bottom: 6px;
        margin-bottom: 12px;
        border-bottom: 1px solid #f4f5f7;
        display: flex;

        >div {
          
        }

        >div:last-child {
          flex: 1;
          text-align: right;
        }
      }

      .msg {
        display: flex;
        justify-content: space-between;
        margin-bottom: 6px;

        >div:first-child {
        }

        &-box {
          display: flex;
          flex: 1;
          justify-content: flex-end;

          &-right {
            
            

            &-bubble {
              margin-right: 10px;
              padding: 8px 15px;  
              background: #95ec69;
              border-radius: 8px;
              position: relative;
              // max-width: 200px;
            }

            &-bubble:before {
              content:"";
              position: absolute;
              right: -8px;
              width: 0;
              height: 0;
              border-top: 4px solid transparent;
              border-left: 8px solid #95ec69;
              border-bottom: 4px solid transparent;
            }

            &-time {
              margin-right: 10px;
              font-size: 12px;
              text-align: right;
            }
          }
        }
      }

      .handle {
        padding-top: 8px;
        text-align: center;
        border-top: 1px solid #f4f5f7;
      }
    }

    &-item:last-child {
      margin-bottom: 0;
    }
      
  }
}

.chatRecord {
    max-height: 60vh;
    overflow-y: auto;
    padding: 20px;


    .labelContent > div {
      overflow: hidden;
      margin-bottom: 16px;
    } 

    .sendBox {
      display: flex;
      align-items: flex-start;
      max-width: 84%;
      font-size: 12px;

      > div {
        // max-width: 100%;
        
      }

      .neme {
        min-width: 16%;
      }
      
      > div .content{
        padding: 8px 10px;
        border-radius: 4px;
        margin-bottom: 4px;
        position: relative;
        word-break: break-all;
      }

      .time {
        font-size: 12px;
      }
    }
    .aiSend > div {
      .content {
        background-color: #00c250;
        color: #fff;
        margin-left: 10px;
      }

      .content:before {
        content:"";
        position: absolute;
        left: -8px;
        top: 6px;
        width: 0;
        height: 0;
        border-top: 4px solid transparent;
        border-right: 8px solid #00c250;
        border-bottom: 4px solid transparent;
      }

      .time {
        margin-left: 10px;
      }
    }

    .kehu > div {
      .content {
        background-color: #f1f1f1;
        margin-right: 10px;
        position: relative;

        .link {
          display: flex;
          align-items: center;

          img {
            width: 50px;
            height: 50px;
            border-radius: 8px;
            margin-right: 6px;
          }

          p {
            margin-top: 10px;
            margin-bottom: 0;
            color: #999;
          }
        }

        .image {
           img {
             max-width: 100%;
             border-radius: 8px;
           }
        }
      }

      .content:before {
        content:"";
        position: absolute;
        right: -8px;
        top: 6px;
        width: 0;
        height: 0;
        border-top: 4px solid transparent;
        border-left: 8px solid #f1f1f1;
        border-bottom: 4px solid transparent;
      }

      .time {
        margin-right: 10px;
      }
    } 
    .sendBox.kehu {
      float: right;
    }
    .sendBox.kehu > span {
      float: right;
    }
}
.screen {
  height: 100%;
  padding: 12px;
  background-color: #f4f5f7;

  .list-item {
    margin-bottom: 20px;
  }

  .item-title {
    font-weight: bold;
  }

  .item-content {
    width: 100%;
    background-color: #fff;
    /* background: red; */
    border: 1px solid #E5E6E7;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    border-radius: 7px;
    margin-top: 10px;
  }

  .item-lable {
    display: inline-block;
    width: auto;
    align-items: center;
    line-height: 36px;
    margin: 10px 3px;
    border-radius: 0;
  }
  
}

.van-picker {
    margin-bottom: 20px;
  }

.showSelectDayBtn {
  padding: 10px 5px;
  display: flex;

  button {
    flex: 1;
    margin:  0 5px;
  }
}
</style>
