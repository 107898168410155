import request from '@/utils/request'

export class ReplyMonitorApi {
  // 列表
  static index (params) {
    return request({
      url: '/replyMonitor/recordIndex',
      method: 'get',
      params
    })
  }

  // 详情
  static show (params) {
    return request({
      url: '/replyMonitor/recordShow',
      method: 'get',
      params
    })
  }

}